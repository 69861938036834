import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {Button, Typography} from "../../component/wrapper/MuiWrappers";
import {useTranslation} from "react-i18next";
import {DownloadLinks} from "./dialog/NodeTreeHelperDialog";
import CommentWidget from "../comment/CommentWidget";
import {ResourceType} from "../../proto/framework/ResourceMessage";
import {useIsAdmin} from "../../hook/CommonUiHook";
import {getObjectId} from "../../api/util/StringUtil";
import {openDialog} from "../../component/dialog/DialogManager";

type Props = {};
const widgetId = getObjectId(101);
const AddonIntroduceWidget = (props: Props) => {
  const {t, i18n} = useTranslation();
  const isAdmin = useIsAdmin();
  if (i18n.language === "zh-CN") {
    return (
        <Stack spacing={2}>
          <Typography variant="h4" component="div">节点树仓库插件文档</Typography>
          {isAdmin && <Button onClick={() =>openDialog("NodeTreeAddonUploadDialog")} variant={"contained"}>更新插件</Button>}
          <Stack direction="column" spacing={2} alignItems={"flex-start"} textAlign={"left"}>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              节点树仓库插件是一款用于在Blender中保存和复用节点树资源的插件。
              它支持在Blender项目间，不同版本Blender间，甚至不同电脑间进行端到端的节点树资源共享。
              其使用Json格式保存节点树结构，同时也能存储节点树引用的材质，图片等资源。
            </Typography>
            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              插件获取
            </Typography>
            <DownloadLinks/>
            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              插件设置
            </Typography>
            <Box component={"img"} src={"/nodetreestore/setting_zh_CN.jpg"} maxWidth={"100%"}/>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>用户名及密码:</strong> 用于登录在线节点树仓库网站，用于获取在线节点树资源以及上传并分享你的本地节点树资源。
              用户名及密码无需专门申请，只需任意选择一个用户名即可使用，但用户名需要全网唯一，先占先得，如果你碰巧
              使用了其他人的用户名，则会看到密码错误的提示，该用户名和密码也可用于登录节点树网站(本网站)。
              如果你曾经在本网站注册过用户名，则也可以直接使用该用户名和密码登录插件。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>节点树保存路径:</strong> 即节点树文件(包括Json和图片资源等)在你本地保存的文件夹路径，你可以选择一个方便的文件夹进行保存，
              你可以将整个文件夹打包转移到任意电脑上使用，只需重新设置路径插件即可找到之前保存的节点树。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>保存带图片的节点组时自动将图片复制到插件资源目录:</strong>
              勾选后在保存节点树时，插件会将节点所引用的图片资源同时保存到上述的节点树保存路径中，
              这样即使原图片被移动或删除，你依然可以正常加载节点树。如果不勾选，则插件只会保存原图片的路径。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>加载材质或几何节点修改器时复用同名节点组:</strong>
              勾选后在加载节点树时，当遇到节点组时，如果项目组中有同名的节点组，则会直接使用已有的同名节点组，而不是新建一个节点组。
              注意这样会导致节点组相互关联，当你修改其中一个节点组时，其他节点组也会一并修改。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>折叠同名项:</strong> 当节点树仓库列表中存在如 "预设1" "预设2"
              "预设3"等同名但数字后缀不同的节点树时，勾选此项会导致只显示数字最大的一项。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>启用菜单:</strong> 除了可以在节点树仓库面板上管理你的节点树以外，你还可以在节点编辑器的菜单和添加节点的菜单中保存和加载你的节点树。可以根据需要选择打开
              注意，该功能会影响到F3搜索面板能否搜索到预设，只有启用了面板，你才能在F3搜索面板中搜索到你保存的预设。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>检查插件更新:</strong> 输入用户名和密码后，插件可以通过访问云端检查是否有新版本的插件，如果有新版本，插件会自动下载并安装。注意更新后可能有功能需要重启Blender才能生效。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>去网页下载插件:</strong> 如果自动更新功能无法使用，你可以点击此按钮，插件会自动打开浏览器并跳转到插件下载页面，你可以手动下载最新版本的插件。
            </Typography>

            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              插件主面板
            </Typography>
            <Box component={"img"} src={"/nodetreestore/main_panel_zh_CN.jpg"} maxWidth={"100%"}/>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              插件面板位于节点编辑器，右侧栏，节点树仓库项中，该面板提供了插件的全部功能，着色器节点，几何节点，合成器节点等略有不同。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong>类别管理:</strong> 位于面板的第一行，其中下拉菜单用于选择当前类别，右侧5个按钮作用分别是新建类别，修改类别名称，删除类别，将类别中的所有节点上传到在线节点树仓库(需登录)，打开类别所在文件夹。
              <strong>高级用法:</strong> 你可以直接在磁盘上新建类别文件夹，删除类别文件夹，任意移动节点树Json文件的位置，节点树仓库会自动同步磁盘上的信息。
            </Typography>

            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              节点树列表: 用于显示和选择当前类别下的节点树名称。列表以名称字符排序。你可以点击列表底部的小三角形进行关键字搜索。
            </Typography>
            <Typography variant="h6" color="textSecondary" enableTranslate={false}>
              主功能区: 位于列表下方的长方形按钮，提供主要功能
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              <strong> 保存: </strong>你需要在节点编辑器中框选出你要保存的节点树结构，然后点击保存，输入名称，则被选中的节点树将会被保存到当前类别下。<br/>
              <strong> 加载: </strong>选择一个节点树名称，点击加载，当前节点树将会被加载到节点编辑器中，并且会跟随你的鼠标移动，加载时不要大幅晃动鼠标，以免鼠标超出节点编辑器而报错。<br/>
              <strong> 复制: </strong>在节点编辑器中框选出你要复制的节点树结构，点击复制，则被选中的节点树结构就会被复制到你的剪切板，你可以在另一个项目中直接点击粘贴来加载被复制的节点树。<br/>
              <strong> 粘贴: </strong>与复制对应，你可以在另一个项目中点击粘贴来加载被复制的节点树。同时，你可以在在线节点树仓库中复制节点树代码，然后在插件中粘贴，插件会自动下载节点树资源并粘贴到你的项目中。注意粘贴时不要大幅晃动鼠标，以免鼠标超出节点编辑器而报错。
              注意在下载节点树资源时，插件会自动检测目标资源是否已经存在在你当前的列表中，如果已经存在同名且内容相同的资源，插件不会覆盖，在这种情况下预览图等资源也不会更新。<br/>
              <strong> 上传: </strong>
              你可以将你的节点树资源上传到在线节点树仓库，这样你就可以在任意电脑上下载你的节点树资源。上传时需要在节点列表中选中一个节点树进行上传。同时需要你在插件设置中设置用户名和密码。
            </Typography>
            <Typography variant="h6" color="textSecondary" enableTranslate={false}>
              副功能区: 位于列表右侧纵向排列的方形图标按钮，提供次要功能
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              包括节点树重命名，删除，覆盖更新，刷新节点列表(从磁盘重新读取类别信息),
              将节点树加载为新的材质槽或几何节点组，批量保存选中的节点组，打开在线节点树仓库等功能
              不同节点类型中，功能略有不同。
            </Typography>
            <Typography variant="h6" color="textSecondary" enableTranslate={false}>
              预览图:
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              节点笔记: 位于主功能区下方，用于显示选中节点树的笔记(如有)，笔记可以在保存节点树时指定，也可以通过重命名按钮来修改。
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              预览图: 插件提供两种方式为你的节点树创建预览图，你可以使用副功能区的相机图标为当前节点树渲染一个预览图，
              这个功能会使用当前场景中的激活相机渲染一个1024*1024的预览图作为节点树的预览图。你也可以使用副功能区的图像图标，
              该功能会将剪贴板中的图片粘贴为当前节点树的预览图。你可以通过截图软件截取你想要的图片，再利用此功能粘贴到插件中。
              你也可以手动添加预览图，直接打开节点树Json文件所在文件夹，创建一个与Json文件同名的.jpg格式的图片文件，插件会自动读取该图片作为预览图。
              推荐使用正方形图片。
            </Typography>
            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              辅助设置面板:
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              位于主面板下方，提供一些与当前节点类型相关的设置，方便查看和随时修改。
            </Typography>
            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              导入与导出:
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              位于辅助设置面板，导出功能可以让用户导出节点树资源到Zip包，方便进行跨设备的资源传输。
              用户可在下拉选项中选择4种不同的导出范围(全部，当前节点树类别，当前列表，以及当前选择的节点树)。
              导入功能则可让用户导入一个Zip包中的所有资源到插件当前的资源路径。
              Zip包中会包含Json文件，预览图，以及节点引用的图片资源等内容，插件在导入时会进行自动去重，同名且内容相同的资源会被忽略。
              不必担心重复导入会导致资源冗余的问题。
            </Typography>
            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              插件菜单
            </Typography>
            <Box component={"img"} src={"/nodetreestore/menu1_zh_CN.jpg"} maxWidth={"100%"}/>
            <Box component={"img"} src={"/nodetreestore/menu2_zh_CN.jpg"} maxWidth={"100%"}/>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              如上图所示，通过菜单也可以直接在节点编辑器中保存和加载节点树。这样你不必每次都需要打开仓库面板进行常用操作。
              为了使你的页面保持简洁，菜单功能可以在设置中启用或禁用。
            </Typography>

            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              在线节点树仓库
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              在线节点树仓库是为节点树仓库用户提供的一个在线的节点树资源的分享平台，实现端到端的资源复用功能。
              其主要目的是分享和获取其他用户分享的节点树资源。需要注意，在线节点树仓库的主要目的是分享，
              而非用于存储大量节点树资源。我们的服务器存储资源有限，请定期清理不需要的在线资源。

              在线节点树仓库会存储由节点树仓库插件上传的节点树资源。所有上传的资源将会被公开展示，其他用户可以查看，下载你分享的节点树资源。
              资源作者可以在线任意修改资源的在线预览图，说明，标签等信息，但名称，元数据等无法修改。资源作者可以随时删除自己的资源。
              在线节点树仓库会对同一账号上传的节点树Json文件进行去重处理，如果多次上传的Json文件内容相同，则只会保留第一份。
              当从在线节点树仓库下载节点树资源时，插件会自动检测你当前列表中是否已经存在同名且内容相同的资源，如果已经存在则不会覆盖。
              插件不提供修改在线资源的功能，如果你需要删除或更新，需要到网页端进行操作。上传的文件大小具有限制，单个文件大小不得超过300MB。
              对于资源的预览图，在线节点树仓库会对图片进行一定的压缩处理，以保证页面加载速度，因此预览图可能会有一定程度的模糊，属于正常现象。

              目前在线节点树仓库处于实验阶段，可能会有不稳定的情况，我们会最大限度地保证资源的安全性和服务的稳定性，
              但请勿依赖在线存储保存重要资源。网站管理员有权修改或删除任何资源，请勿上传违规违法内容。
            </Typography>

            <Typography variant="h5" color="textSecondary" enableTranslate={false}>
              常见问题
            </Typography>

            <Typography variant="h6" color="textSecondary" enableTranslate={false}>
              安装插件后找不到插件面板？
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              1. 节点树仓库的面板位于节点编辑器工作区，而不是3D视图工作区
              2. 你需要在Blender中启用节点才能看见面板，比如，如果你没有启动合成节点，则可能无法看到合成节点编辑器中的面板
              如果你的场景中没有任何物体，也有可能无法激活插件面板。
            </Typography>
            <Typography variant="h6" color="textSecondary" enableTranslate={false}>
              插件能否支持非原生的节点？
            </Typography>
            <Typography variant="body1" color="textSecondary" enableTranslate={false}>
              某些插件可能会为Blender添加非原生的节点，大部分情况下这些节点是可以被支持的，
              但如果那些插件在创建节点时需要执行某些非标准的逻辑，那么这些节点可能无法被支持。节点树仓库不保证能
              支持所有的非原生节点，如果你对某种节点的支持有疑问，可以在购买前联系作者进行确认。
            </Typography>
          </Stack>

          <CommentWidget objectId={widgetId}
                         targetType={ResourceType.RESOURCE_TYPE_WEBPAGE}
                         canDelete={isAdmin}
                         paddingTop={8}
          />

        </Stack>
    )
  }
  return (
      <Stack spacing={2}>
        <Typography variant="h4" component="div">NodeTreeStore Addon Wiki</Typography>
        {isAdmin && <Button onClick={() =>openDialog("NodeTreeAddonUploadDialog")} variant={"contained"}>Update Addon</Button>}
        <Stack direction="column" spacing={2} alignItems={"flex-start"} textAlign={"left"}>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            The NodeTreeStore is a Blender Addon used to save and reuse node tree resources in
            Blender. It offers supports for loading node trees end to end across project, across
            different
            version of Blenders and even across different computers.
            It saves the node tree structure in Json format and can also store resources
            such as materials and images referenced by the node tree.
          </Typography>
          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Get the Addon
          </Typography>
          <DownloadLinks/>
          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Addon Preferences
          </Typography>
          <Box component={"img"} src={"/nodetreestore/setting.jpg"} maxWidth={"100%"}/>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Username and Password:</strong> Used to log in to the online node tree
            repository website to get
            online node tree resources and upload and share your local node tree resources.
            You don't need to register. You can use any username, but the username needs to be
            unique across the network. First come, first served. If you happen to
            use someone else's username, you will see a password error prompt. This username and
            password can also be used to log in to the node tree website (this website).
            If you already have a username and password on this website, you can use it directly.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Store Folder:</strong> The folder path where the node tree file (including Json
            and image
            resources) is saved on your local computer. You can choose a convenient folder to save,
            and you can move the entire folder to any other computers for use. Just reset this addon
            preference to find the previously saved node tree.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Auto-Copy referenced images to the resource folder when saving nodes with
              images:</strong> When
            checked, the addon will copy the images referenced by the node to the Store Folder,
            so even if the original image is moved or deleted, you can still load the node tree. If
            not checked, the addon will only save the path to original image.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Reuse node group with the same name when loading material or geometry node
              modifier: </strong>
            When checked, when loading a node tree, if there is a node group with the same name in
            the project, the addon will directly use the existing node group with the same name
            instead of creating a new node group.
            Notice that this will cause the node groups to be linked. When you modify one of the
            node groups, the other node groups will also be modified.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Collapse item with same name, display the latest one:</strong> When there are
            node trees with the
            same name but different suffixes like "preset1" "preset2" "preset3" in the node tree
            repository list, checking this option will cause only the one with the largest number to
            be displayed.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Enable Menu:</strong> In addition to managing your node tree in the node tree
            repository panel,
            you can also save and load your node tree in the menu of the node editor and the add
            node menu. You can choose to enable it as needed.
            Note that this function will affect whether the F3 search panel can search for presets.
            Only when the menu is enabled, you can search for your saved presets in the F3 search
            Panel.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Check Addon Update</strong> When you have typed in your username and password,
            the addon can check for new versions of the addon by accessing the cloud. If there is a
            new version, the addon will automatically download and install it. Note that after
            updating, some features may require Blender to be restarted to take effect.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Download Addon Online</strong> If the automatic update function is not available,
            you can click this button, and the addon download page will be opened in the browser so
            that you can manually download the latest version of the addon.
          </Typography>

          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Main Panel of the Addon
          </Typography>
          <Box component={"img"} src={"/nodetreestore/main_panel.jpg"} maxWidth={"100%"}/>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            The Main Panel of the Addon is located in the node editor, right sidebar, and
            NodeTreeStore tab. The panel provides all the functions of the addon. The panel for
            shader nodes, geometry nodes, and compositor nodes etc. are slightly different.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong>Category Management:</strong> Located in the first row of the panel, the
            dropdown menu is used to
            select the current category, and the 5 buttons on the right are used to create a new
            category, modify the category name, delete the category, upload all nodes in the
            category
            to the online node tree repository (login required),
            and open the folder on your
            disk.
            <strong>Advanced usage:</strong> You can directly create category folders on the disk,
            delete category
            folders, and move the Json files of the node tree to any location within Store Folder.
            The node tree repository will automatically synchronize the information on the disk.
          </Typography>

          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Node Tree List: Used to display and select the node tree name under the current
            category. The list is sorted by name. You can click the small triangle at the bottom of
            the list to search by keyword.
          </Typography>
          <Typography variant="h6" color="textSecondary" enableTranslate={false}>
            Main Function Area: Located below the list, the rectangular buttons provide the main
            functions
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            <strong> Save: </strong> You need to select the node tree structure you want to save in
            the node
            editor,
            then click save, enter a name, and the selected node tree will be saved under the
            current category. <br/>
            <strong> Load:</strong> Select a node tree item from the list, click load, and that node
            tree will be loaded into the node editor and will follow your mouse movement. Do not
            shake the
            mouse too much when loading to avoid the mouse moving out of the node editor and causing
            an error. <br/>
            <strong> Copy:</strong> Select the node tree structure you want to copy in the node
            editor, click
            copy,
            and the selected node tree structure will be copied to your clipboard. You can
            directly
            click paste in another project to load the copied node tree. <br/>
            <strong> Paste:</strong> Corresponding to copy, you can click paste in another project
            to load the copied node tree. At the same time, you can copy the node tree code from the
            online node tree store, then paste it in the addon, and the addon will automatically
            download the
            node tree resources and paste them into your project. Be careful not to shake the mouse
            too much when pasting to avoid the mouse moving out of the node editor and causing an
            error. Note that when downloading node tree resources, the addon will automatically
            detect whether the target resource already exists in your current list. If there is an
            existing resource with the same name and content, the addon will not overwrite it. In
            this case, preview images and other resources will not be updated. <br/>
            <strong> Upload:</strong> You can upload your node tree resources to the online node
            tree repository so that you can access your node tree resources on any computer. When
            uploading, you
            need to select a node tree in the list to upload. You also need to set your username and
            password in the addon preferences.
          </Typography>
          <Typography variant="h6" color="textSecondary" enableTranslate={false}>
            Secondary Function Area: Located on the right side of the list, the square icon buttons
            column provides secondary functions
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            This includes rename node tree,delete, override the node tree,refresh node list (read category information
            from disk again), Load the node tree as a new material slot
            or geometry node group, batch save selected node groups, open the online node tree
            repository, etc.
            It is slightly different for different node types.
          </Typography>

          <Typography variant="h6" color="textSecondary" enableTranslate={false}>
            Node Preview:
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Node Notes: Located below the main function area, used to display the notes of the
            selected node tree (if exists). Notes can be specified when saving the node tree or
            modified by the rename button.
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Preview Image: The addon provides two ways to create a preview image for your node tree.
            You can use the camera icon in the secondary function area to render a preview image for
            the current node tree. This function will render a 1024*1024 preview image using the
            active
            camera in the current scene as the preview image of the node tree. You can also use the
            image icon in the secondary function area, which will paste the image in your clipboard
            as
            the preview image of the current node tree. You can use a screenshot software to capture
            the image you want, and then use this function to paste it into the addon. You can also
            manually add a preview image by directly opening the folder where the node tree Json
            file
            is located, creating a .jpg file with the same name as the Json file, and the addon will
            automatically read this image as the preview image. A square image is recommended.
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Assistant Setting Panel: Located below the main panel, provides some settings related to
            the current node type, making it easy to view and modify at any time.
          </Typography>
          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Import and Export:
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Located in the assistant setting panel, the export function allows users to export node
            tree resources to a Zip package for easy cross-device resource transfer.
            Users can choose 4 different export scopes (all, current node tree category, current
            list, and the currently selected node tree) in the drop-down options.
            The import function allows users to import all resources in a Zip package to the current
            resource path of the addon.
            The Zip package contains Json files, preview images, and image resources referenced by
            the node, etc. The addon will automatically deduplicate when importing, and resources
            with the same name and content will be ignored.
            Don't worry about redundant resources caused by repeated imports.
          </Typography>
          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Addon Menu
          </Typography>
          <Box component={"img"} src={"/nodetreestore/menu1.jpg"} maxWidth={"100%"}/>
          <Box component={"img"} src={"/nodetreestore/menu2.jpg"} maxWidth={"100%"}/>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            As shown in the figure above, you can also save and load node trees directly in the node
            editor through the menu. This way you don't have to open the repository panel every time
            you need to perform common operations.
            To keep your menu clean, the menu function can be enabled or disabled in the addon
            preference.
          </Typography>

          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            Online NodeTree Store
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            The online node tree store is a platform for node tree store users to share online
            node tree resources, it focus on end-to-end node tree reuse. Its main purpose is to
            share
            and obtain node tree resources shared by other users. Please note that the main purpose
            of the online node tree store is to share, not to store a large number of node tree
            resources for personal purpose. Our server storage resources are limited,
            please clean up unnecessary online resources regularly.
            The online node tree repository stores node tree resources uploaded by the node tree
            store addon. All uploaded resources will be displayed publicly, and other users can
            view and download the node tree resources you share. Resource authors can modify the
            online preview image, description, tags and other information at will, but the name,
            metadata, etc. cannot be modified. Resource authors can delete their resources at any
            time. The online node tree store will deduplicate the node tree Json files uploaded
            by the same account. If the content of multiple uploaded Json files is the same, only
            the
            first copy will be retained. When downloading node tree resources from the online node
            tree store, the addon will automatically check whether the same name and content
            resource already exists in your current list. If it already exists, it will not be
            overwritten. The addon does not provide the function to modify online resources. If you
            need to delete or update, you need to visit this website. The uploaded file size has
            a limit, and the size of a single file cannot exceed 300MB.
            For resource preview images, the online node tree store will compress the images to a
            certain extent to ensure page loading speed. Therefore, the preview image may be
            slightly
            blurred, which is a normal phenomenon.
            The online node tree repository is currently in the experimental stage and may be
            unstable. We will do our best to ensure the security of resources and the availability
            of
            services, but please do not rely on online storage to save important resources. Site
            administrators have the right to modify or delete any resources. Do not upload illegal
            content.
          </Typography>
          <Typography variant="h5" color="textSecondary" enableTranslate={false}>
            FAQ
          </Typography>

          <Typography variant="h6" color="textSecondary" enableTranslate={false}>
            Couldn't find the addon panel after installation?
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            1. The NodeTreeStore panel is located in the node editor workspace, not the 3D view
            2. You need to enable nodes in Blender to see the panel. For example, if you don't
            enable the compositor node, you may not see the panel in the compositor node editor.
            It is also possible that you can't activate the plugin panel if there are no objects in
            your scene.
          </Typography>
          <Typography variant="h6" color="textSecondary" enableTranslate={false}>
            Could the addon support non-native nodes?
          </Typography>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Some addon may bring non-native nodes to Blender. In most cases, these nodes can be
            supported, but if those addons need to execute some non-standard logic when creating
            nodes, these nodes may not be supported. The NodeTreeStore does not guarantee that all
            non-native nodes can be supported. If you have any questions about the support of a
            certain kinds of non-native node, you can contact the author for confirmation before purchasing.
          </Typography>

        </Stack>
        <CommentWidget objectId={widgetId}
                       targetType={ResourceType.RESOURCE_TYPE_WEBPAGE}
                       canDelete={isAdmin}
                       paddingTop={8}
        />

      </Stack>
  );
};

export default AddonIntroduceWidget;