import * as React from 'react';
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {Stack, useTheme} from "@mui/material";
import {useAutoFocus, useHandleDialogClose} from "../../../hook/CommonUiHook";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../component/container/DimensionForm";
import Dropzone from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {Button, Typography} from "../../../component/wrapper/MuiWrappers";
import AlertContext from "../../../context/AlertContext";
import {uploadFile} from "../../storage/FileUploadUtil";
import 'react-image-crop/dist/ReactCrop.css'
import {updateAddon} from "../service/NodeTreeStoreService";
import ClearableTextField from "../../../component/input/ClearableTextField";

export const NodeTreeAddonUploadDialog = React.memo(({
                                                      onDialogClose,
                                                      initialData,
                                                      open,
                                                      ...rest
                                                    }: CloseableDialogProps) => {
  const theme = useTheme();
  const inputRef = useAutoFocus([open]);
  const [loading, setLoading] = React.useState(false);
  const {showAlert} = React.useContext(AlertContext)
  const handleClose = useHandleDialogClose<string>("NodeTreeAddonUploadDialog", onDialogClose);

  const [file, setFile] = React.useState<File | undefined>(void 0);
  const [version, setVersion] = React.useState<string>("");
  const [note, setNote] = React.useState<string>("");


  const handleSubmit = async () => {
    if (file === void 0) {
      showAlert("Please select a file to upload", "error");
      return;
    }
    const versionNum = parseInt(version);
    if (versionNum < 200) {
      showAlert("Version must be greater than 200", "error");
      return;
    }
    if (file?.name.endsWith(".zip") === false) {
      showAlert("File format not supported:" + file?.type, "error");
      return;
    }
    setLoading(true);
    try {
      const fileMetaData = await uploadFile(file, true);
      await updateAddon(versionNum, note, fileMetaData.url);
      showAlert("File uploaded successfully", "success");
      handleClose(true);
    } catch (e) {
      showAlert("File upload failed:" + e, "error");
    } finally {
      setLoading(false);
    }
  }

  return <DimensionDialog
      dialogTitle={"Upload new Version of NodeTreeStore"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleSubmit}
      {...rest}>
    <DimensionForm>
      <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
        {file !== void 0 &&
            <Typography variant={"h6"}>{file.name}</Typography>}
        {file === void 0 && <Dropzone disabled={loading} onDrop={fileList => {
          if (!fileList || fileList.length === 0) {
            return;
          }
          const file = fileList[0];
          // file must be a zip file
          if (!file.name.endsWith(".zip")) {
            showAlert("File format not supported:" + file.type, "error");
            return;
          }
          setFile(file);
        }
        }>
          {({getRootProps, getInputProps}) => (
              <section {...getRootProps()} style={{width: "100%"}}>
                <Stack direction={"row"} spacing={2} border={"dashed"} padding={2}
                       justifyContent={"center"} alignItems={"center"}>
                  <Stack minHeight={300} justifyContent={"center"} alignItems={"center"}>
                    <FileUploadIcon sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "4rem",
                        md: "5rem"
                      }
                    }}/>
                    <Typography sx={{
                      fontSize: {
                        xs: "1.5rem",
                        sm: "1.5rem",
                        md: "1.5rem"
                      },
                      textAlign: "center"
                    }}>{"Drop You File Here"}</Typography>
                  </Stack>
                  <input type={"file"} multiple={false} accept={"application/*"} {...getInputProps()}
                         hidden/>
                </Stack>
              </section>
          )}
        </Dropzone>}
        <ClearableTextField value={version}
                            setValue={setVersion }
                            label={"Version"}
                            type={"number"}
                            placeholder={"Version"}
                            onSubmit={handleSubmit}
                            inputRef={inputRef}/>

        <ClearableTextField value={note}
                            setValue={setNote}
                            label={"Note"}
                            placeholder={"Note"}
                            onSubmit={handleSubmit}/>


        {file !== void 0 &&  <Button variant={"contained"}
                                     disabled={loading}
                                     color={"error"}
                                     disableElevation={true}
                                     onClick={() => {
                                       setFile(void 0);
                                     }}>{"Re Upload"}</Button>}

      </Stack>
    </DimensionForm>
  </DimensionDialog>;
});
