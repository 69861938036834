// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/ResourceMessage.proto

/* eslint-disable */

export const protobufPackage = "proto.framework";

export enum ResourceType {
  /** RESOURCE_TYPE_UNKNOWN - unknown type, not set */
  RESOURCE_TYPE_UNKNOWN = 0,
  /** RESOURCE_TYPE_CUSTOMER - 用户对象 */
  RESOURCE_TYPE_CUSTOMER = 1,
  /** RESOURCE_TYPE_COMMENT - 评论对象 */
  RESOURCE_TYPE_COMMENT = 2,
  /** RESOURCE_TYPE_DRIVER_FILE - 文件对象 */
  RESOURCE_TYPE_DRIVER_FILE = 3,
  /** RESOURCE_TYPE_WEBPAGE - 网页 */
  RESOURCE_TYPE_WEBPAGE = 4,
  /** RESOURCE_TYPE_NODE_TREE - 节点树文件 */
  RESOURCE_TYPE_NODE_TREE = 5,
  UNRECOGNIZED = -1,
}

export function resourceTypeFromJSON(object: any): ResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_UNKNOWN":
      return ResourceType.RESOURCE_TYPE_UNKNOWN;
    case 1:
    case "RESOURCE_TYPE_CUSTOMER":
      return ResourceType.RESOURCE_TYPE_CUSTOMER;
    case 2:
    case "RESOURCE_TYPE_COMMENT":
      return ResourceType.RESOURCE_TYPE_COMMENT;
    case 3:
    case "RESOURCE_TYPE_DRIVER_FILE":
      return ResourceType.RESOURCE_TYPE_DRIVER_FILE;
    case 4:
    case "RESOURCE_TYPE_WEBPAGE":
      return ResourceType.RESOURCE_TYPE_WEBPAGE;
    case 5:
    case "RESOURCE_TYPE_NODE_TREE":
      return ResourceType.RESOURCE_TYPE_NODE_TREE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResourceType.UNRECOGNIZED;
  }
}

export function resourceTypeToJSON(object: ResourceType): string {
  switch (object) {
    case ResourceType.RESOURCE_TYPE_UNKNOWN:
      return "RESOURCE_TYPE_UNKNOWN";
    case ResourceType.RESOURCE_TYPE_CUSTOMER:
      return "RESOURCE_TYPE_CUSTOMER";
    case ResourceType.RESOURCE_TYPE_COMMENT:
      return "RESOURCE_TYPE_COMMENT";
    case ResourceType.RESOURCE_TYPE_DRIVER_FILE:
      return "RESOURCE_TYPE_DRIVER_FILE";
    case ResourceType.RESOURCE_TYPE_WEBPAGE:
      return "RESOURCE_TYPE_WEBPAGE";
    case ResourceType.RESOURCE_TYPE_NODE_TREE:
      return "RESOURCE_TYPE_NODE_TREE";
    case ResourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
