import {pb_pbPost, str_strPost} from "../../api/util/MyDimensionClient";
import {
  CommitUploadRequest,
  FileMetaData,
  FileQueryResponse,
  UploadUrlRequest,
  UploadUrlResponse
} from "../../proto/framework/storage/StorageMessage";
import {PageQuery} from "../../proto/framework/QueryMessage";

const storageAPIPrefix = '/api/v0/storage';

export const getUploadUrl = (file: File, partCount = 1) => {
  const urlRequest: UploadUrlRequest = UploadUrlRequest.create();
  urlRequest.contentSizeInBytes = file.size;
  urlRequest.contentType = file.type;
  urlRequest.partNumber = partCount;
  urlRequest.name = file.name;

  return pb_pbPost(storageAPIPrefix + "/uploadUrl",
      UploadUrlRequest, UploadUrlResponse, urlRequest);
}

export const abortUpload = (uploadId: string) => {
  return str_strPost(storageAPIPrefix + "/abort", uploadId);
}

export const newFolder = (name: string) => {
  return str_strPost(storageAPIPrefix + "/newFolder", name);
}

export const deleteFolder = (folderId: string) => {
  return str_strPost(storageAPIPrefix + "/deleteFolder", folderId);
}

// KeyInFolder: a, a/b, a/b/c
export const commitUpload = async (uploadId: string, etags: string[], isPublic = false, retry = 3, folderId?: string, keyInFolder?: string): Promise<FileMetaData> => {
  const commitRequest = CommitUploadRequest.create();
  commitRequest.id = uploadId;
  commitRequest.etag = etags;
  commitRequest.isPublic = isPublic;
  if (folderId) {
    if (!keyInFolder) {
      throw new Error("KeyInFolder required with folderId");
    }
    commitRequest.folderId = folderId;
  }
  if (keyInFolder) {
    if (!folderId) {
      throw new Error("KeyInFolder requires folderId");
    }
    if (keyInFolder.startsWith("/") || keyInFolder.endsWith("/")) {
      throw new Error("KeyInFolder should not start or end with /");
    }
    commitRequest.keyInFolder = keyInFolder;
  }
  try {
    return await pb_pbPost(storageAPIPrefix + "/commit", CommitUploadRequest, FileMetaData, commitRequest);
  } catch (err) {
    if (retry > 0) {
      return new Promise<FileMetaData>((resolve, reject) => {
        setTimeout(() => {
          resolve(commitUpload(uploadId, etags, isPublic, retry - 1, folderId, keyInFolder));
        }, 5000);
      });
    }
    throw err;
  }
}

export const queryFiles = (page: number = 0, pageSize: number = 20) => {
  const pageQuery = PageQuery.create();
  pageQuery.page = page;
  pageQuery.pageSize = pageSize;
  return pb_pbPost(storageAPIPrefix + "/query", PageQuery, FileQueryResponse, pageQuery);
}

export const deleteFile = (fileId: string) => {
  return str_strPost(storageAPIPrefix + "/delete", fileId);
}