import * as React from 'react';
import {useEffect} from 'react';
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {Stack, useTheme} from "@mui/material";
import {useHandleDialogClose} from "../../../hook/CommonUiHook";
import {useAppSelector} from "../../../api/redux/hooks";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../component/container/DimensionForm";
import Dropzone from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {Button, Typography} from "../../../component/wrapper/MuiWrappers";
import AlertContext from "../../../context/AlertContext";
import {compressImage} from "../../../api/util/ImageUtil";
import {uploadFile} from "../../storage/FileUploadUtil";
import ReactCrop, {type Crop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ImageContainer from "../../../component/container/ImageContainer";
import {selectInitialData} from "../../../api/redux/slice/DialogSlice";
import {updateAvatar} from "../service/NodeTreeStoreService";
import OneOf from "../../../component/container/OneOf";
import {NodeTreePreviewDefaultImg} from "../component/NodeTreePreviewDefaultImg";

export const EditNodeTreeAvatarDialog = React.memo(({
                                       onDialogClose,
                                       initialData,
                                       open,
                                       ...rest
                                     }: CloseableDialogProps) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const {showAlert} = React.useContext(AlertContext)
  const handleClose = useHandleDialogClose<string>("EditNodeTreeAvatarDialog", onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData);
  const [file, setFile] = React.useState<File | undefined>(void 0);
  const [fileToUpload, setFileToUpload] = React.useState<File | undefined>(void 0);
  const [localFileUrl, setLocalFileUrl] = React.useState<string | undefined>(void 0);
  const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(void 0);
  const [crop, setCrop] = React.useState<Crop>();
  // const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const nodeTreeId = initialData?.nodeTreeId || initialDataFromManager?.nodeTreeId;
  const nodeTreeType = initialData?.type || initialDataFromManager?.type;
  const initialUrl = initialData?.avatarUrl || initialDataFromManager?.avatarUrl;

  const getCropBlob = (c: Crop) => {
    const cvs = previewCanvasRef.current;
    const ctx = previewCanvasRef.current?.getContext('2d');
    const img = imgRef.current;
    if (!ctx || !cvs || !img) {
      return;
    }

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    cvs.width = c.width
    cvs.height = c.height

    ctx.drawImage(
        img,
        c.x * scaleX,
        c.y * scaleY,
        c.width * scaleX,
        c.height * scaleY,
        0,
        0,
        cvs.width,
        cvs.height
    );

    cvs.toBlob((blob) => {
          if (blob === void 0 || blob === null) {
            return;
          }
          const url = URL.createObjectURL(blob);
          setPreviewUrl(url);
          const newFile = new File([blob], file?.name || "preview.jpg", {type: file?.type || "image/jpeg"});
          setFileToUpload(newFile);
        }
    );

  }

  useEffect(() => {
    if (localFileUrl !== void 0) {
      URL.revokeObjectURL(localFileUrl);
    }
    setLocalFileUrl(file ? URL.createObjectURL(file) : void 0);

    if (previewUrl !== void 0) {
      URL.revokeObjectURL(previewUrl);
    }
    setPreviewUrl(file ? URL.createObjectURL(file) : void 0);

    return () => {
      if (localFileUrl !== void 0) {
        URL.revokeObjectURL(localFileUrl);
      }
      if (previewUrl !== void 0) {
        URL.revokeObjectURL(previewUrl);
      }
    }
  }, [file]);

  const getPreviewUrl = () => {
    if (previewUrl) {
      return previewUrl;
    }
    if (localFileUrl) {
      return localFileUrl;
    }
    return initialUrl;
  }

  const handleEditPreview = async () => {
    setLoading(true);

    const finalFile = fileToUpload ?? file;

    if (finalFile !== void 0) {
      try {
        // compress image
        const compressedFile = await compressImage(finalFile, 10);
        // upload file
        const fileMetaData = await uploadFile(compressedFile, true);

        await updateAvatar(nodeTreeId,fileMetaData.id);

        // set avatar url
        handleClose(true, fileMetaData.id);
      } catch (error) {
        showAlert("File upload failed, please retry: " + error, "error");
      } finally {
        setLoading(false);
      }
      // set avatar url
      return;
    }
  }

  return <DimensionDialog
      dialogTitle={"Upload an Preview for your NodeTree"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditPreview}
      {...rest}>
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
      <OneOf index={getPreviewUrl() === void 0 ? 0 : 1}>
        <NodeTreePreviewDefaultImg type={nodeTreeType} height={"30dvh"}/>
        <ImageContainer src={getPreviewUrl()}
                        sx={{width: "100%", maxHeight: "30dvh", objectFit: "contain"}}
                        skeletonSx={{height: "30dvh"}}/>
      </OneOf>
    </Stack>
    <DimensionForm infoMessage={"A square image is recommended"}>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        {file === void 0 && <Dropzone disabled={loading} onDrop={fileList => {
          if (!fileList || fileList.length === 0) {
            return;
          }
          const file = fileList[0];
          if (!file.type.startsWith("image")) {
            showAlert("File format not supported", "error");
            return;
          }
          setFile(file);
        }
        }>
          {({getRootProps, getInputProps}) => (
              <section {...getRootProps()} style={{width: "100%"}}>
                <Stack direction={"row"} spacing={2} border={"dashed"} padding={2}
                       justifyContent={"center"} alignItems={"center"}>
                  <Stack minHeight={300} justifyContent={"center"} alignItems={"center"}>
                    <FileUploadIcon sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "4rem",
                        md: "5rem"
                      }
                    }}/>
                    <Typography sx={{
                      fontSize: {
                        xs: "1.5rem",
                        sm: "1.5rem",
                        md: "1.5rem"
                      },
                      textAlign: "center"
                    }}>{"Drop You File Here"}</Typography>
                  </Stack>
                  <input type={"file"} multiple={true} {...getInputProps()}
                         hidden/>
                </Stack>
              </section>
          )}
        </Dropzone>}
        {/*image cropper here*/}
        {file !== void 0 && <Stack spacing={1}>
          <ReactCrop crop={crop} onChange={c => setCrop(c)} onComplete={(c) => {
            getCropBlob(c);
          }}>
            <img src={localFileUrl} ref={imgRef}/>
          </ReactCrop>
          <Button variant={"contained"}
                  disabled={loading}
                  color={"error"}
                  disableElevation={true}
                  onClick={() => {
                    setFile(void 0);
                  }}>{"Re Upload"}</Button>
          <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: crop?.width,
                height: crop?.height,
                display: 'none',
              }}
          />
        </Stack>
        }
      </Stack>
    </DimensionForm>
  </DimensionDialog>;
});
