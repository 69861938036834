import {NodeTreeProfile} from "../../proto/application/nodetreestore/NodeTreeStoreMessage";
import {CollectorType} from "../../proto/framework/collector/CollectorMessage";

export const typeToLabel = (type: string) => {
  switch (type) {
    case "ShaderNodeTree":
      return "Shader Nodes";
    case "GeometryNodeTree":
      return "Geometry Nodes";
    case "TextureNodeTree":
      return "Texture Nodes";
    case "CompositorNodeTree":
      return "Compositor Nodes";
    case "luxcore_material_nodes":
      return "LuxCore Material Nodes";
    case "luxcore_volume_nodes":
      return "LuxCore Volume Nodes";
    case "octane_composite_nodes":
      return "Octane Composite Nodes";
    case "octane_render_aov_nodes":
      return "Octane Render AOV Nodes";
    case "octane_kernel_nodes":
      return "Octane Kernel Nodes";
    case "MaltTree":
      return "Malt Nodes";
    case "VRayNodeTreeEditor":
      return "VRay Nodes";
    default:
      return type;
  }
}

export const getEngineLabel = (engine: string) => {
  engine = engine.toLowerCase();
  switch (engine) {
    case "cycles":
      return "Cycles";
    case "blender_eevee":
      return "Eevee";
    case "blender_eevee_next":
      return "Eevee Next";
    case "octane":
      return "Octane";
    case "malt":
      return "Malt";
    case "vray":
      return "VRay";
    default:
      return engine;
  }
}

export const getTypeColor = (type: string) => {
  switch (type) {
    case "Shader Nodes":
    case "ShaderNodeTree":
      return "#275B27"
    case "Geometry Nodes":
    case "GeometryNodeTree":
      return "#1D725E"
    case "Compositor Nodes":
    case "CompositorNodeTree":
      return "#3F2750"
    case "Texture Nodes":
    case "TextureNodeTree":
      return "#79461D"
    default:
      return "#484749"
  }
}

export const getEngineColor = (engine: string) => {
  engine = engine.toLowerCase();
  switch (engine) {
    case "cycles":
      return "#E19658";
    case "blender_eevee":
    case "blender_eevee_next":
      return "#00D4A3";
    // case "octane":
    //   return "#3F2750";
    // case "malt":
    //   return "#79461D";
    // case "vray":
    //   return "#484749";
    default:
      return "#484749";
  }
}

export const getUserLike = (profile: NodeTreeProfile) => {
  if (!profile.userCollectState) {
    return false;
  }
  for (let state of profile.userCollectState.collectStates) {
    if (state.type === CollectorType.COLLECTOR_TYPE_LIKE) {
      return true;
    }
  }
  return false;
}

export const getCount = (profile: NodeTreeProfile, type: CollectorType) => {
  if (!profile.collectProfile || !profile.collectProfile?.profiles) {
    return 0;
  }
  for (let state of profile.collectProfile.profiles) {
    if (state.type === type) {
      return state.count;
    }
  }
  return 0;
}

export const getLikeCount = (profile: NodeTreeProfile) => {
  return getCount(profile, CollectorType.COLLECTOR_TYPE_LIKE);
}

export const getViewCount = (profile: NodeTreeProfile) => {
  return getCount(profile, CollectorType.COLLECTOR_TYPE_VIEW);
}

export const getDownloadCount = (profile: NodeTreeProfile) => {
  return getCount(profile, CollectorType.COLLECTOR_TYPE_DOWNLOAD);
}