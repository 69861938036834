
export const root_path = ""; // root path is empty string, or need to end with "/"
export const get_root_url = () => {
  return "/" + root_path;
}
export const get_url = (path: string) => {
  return get_root_url() + path;
}

export const get_abs_url = (path: string) => {
  return window.location.origin +"/#"+ get_url(path);
}

export const full_screen_path = "full";
export const get_full_screen_url = () => {
  return "/" + full_screen_path;
}
export const get_full_url = (path: string) => {
  return get_full_screen_url() + "/" + path;
}

export const index_path = "index";
export const get_index_url = () => {
  return get_url(index_path);
}

export const account_path = "account";
export const get_account_url = () => {
  return get_url(account_path);
}
export const policy_path = "policy";
export const get_policy_url = () => {
  return get_url(policy_path);
}

// export const github_oauth_path = "github_oauth";
// export const get_github_oauth_url = () => {
//   return get_url(github_oauth_path);
// }

export const customer_path = "customer";
export const get_customer_url = (customer_id?: string) => {
  if (customer_id) {
    return get_url(customer_path) + "?id=" + customer_id;
  }
  return get_url(customer_path);
}

export const storage_path = "storage";
export const get_storage_url = () => {
  return get_url(storage_path);
}

export const ai_chat_path = "chatgpt";
export const get_ai_chat_url = (thread_id?: string) => {
  if (thread_id) {
    return get_url(ai_chat_path) + "?id=" + thread_id;
  }
  return get_url(ai_chat_path);
}

export const driver_path = "driver";
export const get_driver_url = (path_id?: string) => {
  if (path_id) {
    return get_url(driver_path) + "?id=" + path_id;
  }
  return get_url(driver_path);
}

export const node_tree_store_path = "nodetree";
export const get_node_tree_store_url = (query?: string, owner?: string, type?: string, engine?: string) => {
  if (query || owner || type || engine) {
    return get_url(node_tree_store_path) + "?q=" + (query ?? "") + "&o=" +( owner ?? "") + "&t=" + (type ?? "") + "&e=" + (engine ?? "");
  }
  return get_url(node_tree_store_path);
}

export const node_tree_item_path = "nodetreeitem";
export const get_node_tree_item_url = (id: string) => {
  return get_url(node_tree_item_path) + "?id=" + id;
}

export const node_tree_store_addon_intro_path = "nodetreestore";
export const get_node_tree_store_addon_intro_url = () => {
  return get_url(node_tree_store_addon_intro_path);
}

export const gallery_path = "gallery";
export const get_gallery_url = () => {
  return get_url(gallery_path);
}

export const notification_path = "notification";
export const get_notification_url = () => {
  return get_url(notification_path);
}

export const analytics_path = "analytics";
export const get_analytics_url = () => {
  return get_url(analytics_path);
}

export const mmd_model_path = "mmd_model";
export const get_mmd_model_path = () => {
  return get_full_url(mmd_model_path);
}