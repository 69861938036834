import {createHashRouter} from "react-router-dom";
import Portal from "../domain/website/page/Portal";
import React, {Suspense} from "react";
import LoadingScreen from "../domain/website/page/LoadingScreen";
import IndexWidget from "../domain/index/IndexWidget";
import RequireLoginInfo from "../component/container/RequireLoginInfo";

import {
  account_path,
  ai_chat_path,
  analytics_path,
  customer_path,
  driver_path,
  gallery_path,
  get_full_screen_url,
  get_mmd_model_path,
  get_root_url,
  index_path,
  node_tree_item_path,
  node_tree_store_addon_intro_path,
  node_tree_store_path,
  notification_path,
  policy_path,
  root_path,
} from "./routerUtil";
import {RequireAdmin} from "../component/container/RequireAdmin";
import FullScreen from "../domain/website/page/FullScreen";
import AddonIntroduceWidget from "../domain/nodeTreeStore/AddonIntroduceWidget";

const WebSitePolicy = React.lazy(() => import("../domain/website/page/WebSitePolicy"));
const NotFound = React.lazy(() => import("../domain/website/page/NotFound"));
const UserCredentialPanel = React.lazy(() => import("../domain/account/component/UserCredentialPanel"));
const CustomerProfileWidget = React.lazy(() => import("../domain/customer/CustomerProfileWidget"));
const AiChatRoom = React.lazy(() => import("../domain/aiChatroom/AiChatRoom"));
const DriverWidget = React.lazy(() => import("../domain/driver/DriverWidget"));
const GalleryWidgetProps = React.lazy(() => import("../domain/gallery/GalleryWidget"));
const NotificationWidget = React.lazy(() => import("../domain/notification/NotificationWidget"));
const AnalyticWidget = React.lazy(() => import("../domain/analytics/AnalyticWidget"));
const ThreeModel = React.lazy(() => import("../domain/website/page/3D/ThreeModel"));
const BabyLonMmd = React.lazy(() => import("../domain/website/page/3D/BabyLonMMD"));
const NodeTreeItemWidget = React.lazy(() => import("../domain/nodeTreeStore/NodeTreeItemWidget"));
const NodeTreeStoreWidget = React.lazy(() => import("../domain/nodeTreeStore/NodeTreeStoreWidget"));
const routes = [
  {
    path: get_root_url(),
    element: <Portal/>,
    children: [
      {
        path: root_path,
        element: <Suspense fallback={<LoadingScreen/>}><NodeTreeStoreWidget/></Suspense>
        // element: <Suspense fallback={<LoadingScreen/>}><IndexWidget/></Suspense>
      },
      {
        path:index_path,
        element: <Suspense fallback={<LoadingScreen/>}><IndexWidget/></Suspense>
      },
      {
        path: account_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><UserCredentialPanel/></RequireLoginInfo></Suspense>,
      },
      {
        path: customer_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><CustomerProfileWidget/></Suspense>
      },
      {
        path: ai_chat_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><AiChatRoom/></RequireLoginInfo></Suspense>
      },
      {
        path: driver_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><DriverWidget/></RequireLoginInfo></Suspense>
      },
      {
        path:node_tree_store_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><NodeTreeStoreWidget/></Suspense>
      },
      {
        path:node_tree_item_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><NodeTreeItemWidget/></Suspense>
      },
      {
        path: node_tree_store_addon_intro_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><AddonIntroduceWidget/></Suspense>
      },
      {
        path: gallery_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><GalleryWidgetProps/></Suspense>
      },
      {
        path: notification_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><NotificationWidget/></RequireLoginInfo></Suspense>
      },
      {
        path: analytics_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><RequireAdmin><AnalyticWidget/></RequireAdmin></RequireLoginInfo></Suspense>
      },
      {
        path: policy_path,
        element: <Suspense fallback={<LoadingScreen/>}> <WebSitePolicy/></Suspense>
      },
      {
        path: "*",
        element: <Suspense fallback={<LoadingScreen/>}><NotFound/></Suspense>

      }
    ]
  },
  {
    path: get_full_screen_url(),
    element: <FullScreen/>,
    children: [
      {
        path: root_path,
        element: <Suspense fallback={<LoadingScreen/>}><BabyLonMmd/></Suspense>

      },
      {
        path: get_mmd_model_path(),
        element: <Suspense fallback={<LoadingScreen/>}><ThreeModel/></Suspense>

      },
      {
        path: "*",
        element: <Suspense fallback={<LoadingScreen/>}><NotFound/></Suspense>

      },
    ],
  },

  {
    path: "/*",
    element: <Suspense fallback={<LoadingScreen/>}><NotFound/></Suspense>
  },
] as ({
  path: string,
  element: JSX.Element,
  children: { name: string, path: string, element: JSX.Element }[]
} | { path: string, element: JSX.Element, children?: undefined })[];
// history模式路由
// const rootRouter = createBrowserRouter(routes);
// hash模式路由
const rootRouter = createHashRouter(routes);

export default rootRouter;
export {routes};