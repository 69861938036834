import React from "react";
import {AppBar, AppBarProps, Box, Divider, Drawer, IconButton, Stack, Toolbar} from "@mui/material";
import {Link} from 'react-router-dom';
import HiveIcon from '@mui/icons-material/Hive';
import LanguageSwitcher from "../domain/website/setting/LanguageSwitcher";
import ContentButton from "../component/button/ContentButton";
import MenuButton from "../component/button/MenuButton";
import LanIcon from '@mui/icons-material/Lan';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PolicyIcon from '@mui/icons-material/Policy';
import MenuIcon from '@mui/icons-material/Menu';
import SmartDivider from "../domain/website/component/SmartDivider";
import DateDisplay from "../component/wrapper/DateDisplay";
import "./navigation.css";
import ParticleArea from "../domain/website/effect/ParticleArea";
import {useAppSelector} from "../api/redux/hooks";
import {selectEnableParticle} from "../api/redux/slice/SettingSlice";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ArticleIcon from '@mui/icons-material/Article';
import BreakpointContainer from "../component/container/BreakpointContainer";
import AccountDropdown from "../domain/account/component/AccountDropdown";
import CollectionsIcon from '@mui/icons-material/Collections';
import PolylineIcon from '@mui/icons-material/Polyline';
import {SiBabylondotjs} from "react-icons/si";
import {TbBrandThreejs} from "react-icons/tb";
import HtmlIcon from '@mui/icons-material/Html';
import {
  get_ai_chat_url,
  get_analytics_url,
  get_driver_url,
  get_full_screen_url,
  get_gallery_url,
  get_index_url,
  get_mmd_model_path,
  get_node_tree_store_addon_intro_url,
  get_node_tree_store_url,
  get_policy_url
} from "./routerUtil";
import ListComponent from "../component/container/ListComponent";
import {menuItemStyle} from "../api/util/constants";
import {in_prod} from "../api/applicationConfig";
import InsightsIcon from '@mui/icons-material/Insights';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

type MenuNavItem = {
  title: string;
  detail?: string;
  icon?: React.ReactNode;
  children: PlainNavItem[];
}

type PlainNavItem = {
  title: string;
  to: string;
  detail?: string;
  icon?: React.ReactNode;
}

const menuIconStyle = {
  height: "32px", width: "32px"
}

const menuNavItems: MenuNavItem[] = [

  {
    title: "Service",
    icon: <LanIcon/>,
    children: [
      {
        title: "Node Tree",
        to: get_node_tree_store_url(),
        detail: "View Node Tree Asserts",
        icon: <PolylineIcon style={menuIconStyle}/>
      },
      {
        title: "ChatGPT",
        to: get_ai_chat_url(),
        detail: "Talk with AI for free",
        icon: <LiveHelpIcon style={menuIconStyle}/>
      },
      {
        title: "WebDriver",
        to: get_driver_url(),
        icon: <CloudDoneIcon style={menuIconStyle}/>,
        detail: "Manage File on Cloud"
      },
      // {
      //   title: "Search",
      //   to: "/search",
      //   detail: "Search Engine",
      //   icon: <GoogleIcon style={menuIconStyle}/>
      // },
    ]
  },

  {
    title: "Wiki",
    icon: <ArticleIcon/>,
    children: [
      {
        title: "Node Tree Store",
        to: get_node_tree_store_addon_intro_url(),
        detail: "Blender Addon",
        icon:<PolylineIcon style={menuIconStyle}/>
      },
      {
        title: "About this Website",
        to: get_index_url(),
        detail: "Website Technology",
        icon:<HtmlIcon style={menuIconStyle}/>
      },
    ]
  },

  {
    title: "3D",
    icon: <ViewInArIcon/>,
    children: [
      {
        title: "BabyLon",
        to: get_full_screen_url(),
        icon: <SiBabylondotjs style={menuIconStyle}/>,
        detail: "BabyLon 3D Engine"
      },
      {
        title: "threeJs",
        to: get_mmd_model_path(),
        detail: "3D Model Viewer",
        icon: <TbBrandThreejs style={menuIconStyle}/>
      },
    ]
  },

];

const plainNavItems: PlainNavItem[] = [
  // {title: "About", to: "/about", icon: <InfoIcon/>},
  {title: "Policy", to: get_policy_url(), icon: <PolicyIcon/>},
  // {title: "3D", to: get_mmd_model_path(), icon: <ViewInArIcon/>},
  {title: "Analytic", to: get_analytics_url(), icon: <InsightsIcon/>},
]

if (!in_prod) {
  plainNavItems.push({title: "Gallery", to: get_gallery_url(), icon: <CollectionsIcon/>});
}


const mobileNavitemStyle = {
  minWidth: "250px", display: "flex", justifyContent: "flex-start",
  px: 3,
  my: 1,
  fontSize: "1.2rem",
  fontWeight: 400,
  "& .MuiButton-endIcon": {
    position: "absolute",
    right: "1.3rem",
  },
};


const PortalNavigationBar = ({
                               color = "transparent",
                               elevation = 0,
                               position = "static",
                               ...rest
                             }: AppBarProps) => {

  const [rightDrawerState, setRightDrawerState] = React.useState(false);
  const enableParticle = useAppSelector(selectEnableParticle);

  return <AppBar position={position}
                 elevation={elevation}
                 className={"main_navi"}
                 color={color} {...rest}>{/*appbar is a vertical flex container*/}

    {enableParticle && <ParticleArea id="tsparticles"/>}
    <BreakpointContainer up={"md"}>
      <Toolbar className={"main_navi"}>{/* toolbar is a flex container*/}
        <ContentButton title={"My Dimension"} to={"/"}
                       icon={<HiveIcon/>} sx={{
          backgroundColor: "transparent"
        }}/>

        <ListComponent data={menuNavItems} renderItem={({
                                                          title,
                                                          detail,
                                                          icon,
                                                          children,
                                                        }: MenuNavItem) => {
          return <MenuButton title={title} icon={icon} detail={detail} key={title}
                             sx={{backgroundColor: "transparent"}}
                             menuItems={children.map(({
                                                        title,
                                                        to,
                                                        detail,
                                                        icon
                                                      }: PlainNavItem) => <ContentButton
                                 key={title}
                                 title={title}
                                 fullWidth={true}
                                 component={Link}
                                 to={to} detail={detail}
                                 sx={menuItemStyle}
                                 icon={icon}></ContentButton>)}></MenuButton>;
        }}/>

        {/*inner menu 二级菜单*/}
        {/*<NavMenu title={"test"} menuItems={[*/}
        {/*  <NavMenu title={"test"} menuItems={[*/}
        {/*    <NavItem title={"test1"} to={"/"}></NavItem>,*/}
        {/*  ]}></NavMenu>*/}
        {/*]}></NavMenu>*/}

        <ListComponent data={plainNavItems}
                       renderItem={({title, to, detail, icon}: PlainNavItem) => <ContentButton
                           sx={{backgroundColor: "transparent"}}
                           title={title}
                           key={title}
                           component={Link}
                           to={to}
                           detail={detail}
                           icon={icon}
                       />}/>
        <Box style={{flexGrow: 1}}/>
        <Stack direction={"row"} spacing={2}>
          <AccountDropdown/>
          <LanguageSwitcher sx={{backgroundColor: "transparent"}}/>
        </Stack>
      </Toolbar>
    </BreakpointContainer>

    <BreakpointContainer down={"md"}>
      <Toolbar className={"main_navi"}>{/* toolbar is a flex container*/}

        <AccountDropdown/>
        <Box style={{flexGrow: 1}}/>

        <Stack direction={"row"} spacing={2}>
          <IconButton aria-label={"menu"} color={"inherit"}
                      onClick={() => setRightDrawerState(true)}>
            <MenuIcon/>
          </IconButton>
          <Drawer
              anchor={"right"}
              open={rightDrawerState}
              onClose={() => setRightDrawerState(false)}
              className={"blurDialog"}
          >
            <ContentButton title={"My Dimension"} to={"/"}
                           onClick={() => setRightDrawerState(false)}
                           icon={<HiveIcon/>} sx={mobileNavitemStyle}/>
            <Divider/>
            <ListComponent data={menuNavItems}
                           renderItem={({title, detail, icon, children}: MenuNavItem) => {
                             return <MenuButton title={title} icon={icon} detail={detail}
                                                key={title}
                                                menuAlign={"right"} sx={mobileNavitemStyle}

                                                menuItems={children.map(({
                                                                           title,
                                                                           to,
                                                                           detail,
                                                                           icon
                                                                         }: PlainNavItem) =>
                                                    <ContentButton
                                                        key={title}
                                                        title={title}
                                                        component={Link}
                                                        onClick={() => setRightDrawerState(false)}
                                                        to={to} detail={detail} icon={icon}
                                                        sx={mobileNavitemStyle}></ContentButton>)}></MenuButton>;
                           }}/>
            <ListComponent data={plainNavItems}
                           renderItem={({title, to, detail, icon}: PlainNavItem) => {
                             return <ContentButton title={title}
                                                   component={Link}
                                                   key={title}
                                                   to={to}
                                                   detail={detail}
                                                   onClick={() => setRightDrawerState(false)}
                                                   icon={icon}
                                                   sx={mobileNavitemStyle}/>
                           }}/>
            <LanguageSwitcher sx={mobileNavitemStyle}/>
            <Box style={{flexGrow: 1}}/>

            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}
                   sx={{
                     width: "100%",
                     height: "auto",
                     paddingTop: "1rem",
                     marginTop: "2rem",
                     marginBottom: "1rem",
                   }}>
              <SmartDivider/>
              <DateDisplay variant={"body1"}/>
            </Stack>

          </Drawer>
        </Stack>
      </Toolbar>
    </BreakpointContainer>
  </AppBar>;
}

export default React.memo(PortalNavigationBar);