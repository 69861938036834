import {
  pb_pbPost,
  pb_strPost,
  str_pbPost,
  str_strPost,
  throttle
} from "../../../api/util/MyDimensionClient";
import {PageQuery} from "../../../proto/framework/QueryMessage";
import {
  NodeTreeAddonCommitRequest,
  NodeTreeProfile,
  NodeTreeQuery,
  NodeTreeQueryResponse,
  NodeTreeUpdateRequest,
  SliceResponse,
  SortType
} from "../../../proto/application/nodetreestore/NodeTreeStoreMessage";
import {collect, unCollect} from "../../collector/CollectorService";
import {CollectorType} from "../../../proto/framework/collector/CollectorMessage";
import {ResourceType} from "../../../proto/framework/ResourceMessage";


const nodeTreeAPIPrefix = '/api/v0/node_tree_store';

export const getPageQuery = (page=0, pageSize=30) => {
  const pageQuery = PageQuery.create();
  pageQuery.page = page;
  pageQuery.pageSize = pageSize;
  return pageQuery;
}


export const querySlice = throttle(() => {
  return str_pbPost(nodeTreeAPIPrefix + "/slice", SliceResponse);
}, 1000 * 5 * 60);

export const queryNodeTree = throttle((
    query?: string,
    ownerId?: string,
    type?: string,
    engine?: string,
    pageQuery?: PageQuery,
    sortType?: SortType,
) => {

  const queryRequest = NodeTreeQuery.create();
  if (query && query.length > 0) {
    queryRequest.query = query;
  }
  if (ownerId && ownerId.length > 0) {
    queryRequest.ownerId = ownerId;
  }
  if (type && type.length > 0) {
    queryRequest.type = type;
  }
  if (engine && engine.length > 0) {
    queryRequest.engine = engine;
  }
  if (pageQuery) {
    queryRequest.pageQuery = pageQuery;
  } else {
    queryRequest.pageQuery = getPageQuery();
  }
  if (sortType) {
    queryRequest.sortType = sortType;
  } else {
    queryRequest.sortType = SortType.DEFAULT;
  }
  return pb_pbPost(nodeTreeAPIPrefix + "/query", NodeTreeQuery, NodeTreeQueryResponse, queryRequest);
});

export const getNodeTree = throttle((id: string) => {
  return str_pbPost(nodeTreeAPIPrefix + "/getDetail", NodeTreeProfile, id);
});

export const likeNodeTree = (id: string) => {
  return collect(CollectorType.COLLECTOR_TYPE_LIKE, ResourceType.RESOURCE_TYPE_NODE_TREE, id);
}

export const unlikeNodeTree = (id: string) => {
  return unCollect(CollectorType.COLLECTOR_TYPE_LIKE, ResourceType.RESOURCE_TYPE_NODE_TREE, id);
}

export const updateNodeTree = throttle((updateRequest: NodeTreeUpdateRequest) => {
  return pb_strPost(nodeTreeAPIPrefix + "/update", NodeTreeUpdateRequest, updateRequest);
});

export const updateDescription = (id: string, description: string) => {
  const updateRequest = NodeTreeUpdateRequest.create();
  updateRequest.id = id;
  updateRequest.description = description;
  return updateNodeTree(updateRequest);
};

export const updateAvatar = (id: string, avatar: string) => {
  const updateRequest = NodeTreeUpdateRequest.create();
  updateRequest.id = id;
  updateRequest.avatarId = avatar;
  return updateNodeTree(updateRequest);
}

export const updateTags = (id: string, tags: string[]) => {
  const updateRequest = NodeTreeUpdateRequest.create();
  updateRequest.id = id;
  updateRequest.tags = tags;
  return updateNodeTree(updateRequest);
}

export const deleteNodeTree = (id: string) => {
  return str_strPost(nodeTreeAPIPrefix + "/delete", id);
}

export const updateAddon = (version: number, note: string, url: string) => {
  const commitRequest = NodeTreeAddonCommitRequest.create();
  commitRequest.version = version;
  commitRequest.note = note;
  commitRequest.url = url;
  return pb_strPost(nodeTreeAPIPrefix + "/commitAddon", NodeTreeAddonCommitRequest, commitRequest);
}

// export const clearAll = () => {
//   return str_strPost(nodeTreeAPIPrefix + "/clearAll");
// }
