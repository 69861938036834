import * as React from 'react';
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {useHandleDialogClose} from "../../../hook/CommonUiHook";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import OneOf from "../../../component/container/OneOf";
import {Typography} from "../../../component/wrapper/MuiWrappers";

import {get_abs_url, node_tree_store_addon_intro_path} from "../../../navigation/routerUtil";

export const DownloadLinks = () => {
  return <ul>
    <li><a href={"https://gf.bilibili.com/item/detail/1106250015"} target={"_blank"}>B站工坊</a>
    </li>
    <li><a href={"https://afdian.com/item/ec8f2d6e7c5411eda24952540025c377"}
           target={"_blank"}>爱发电</a></li>
    <li><a href={"https://blendermarket.com/products/blender-nodetreestore?search_id=37659773"}
           target={"_blank"}>Blender Market</a></li>
  </ul>;
}

const NodeTreeHelperDialog = ({onDialogClose, open, ...rest}: CloseableDialogProps) => {
  const handleClose = useHandleDialogClose("NodeTreeHelperDialog", onDialogClose);
  const {t, i18n} = useTranslation();
  return <DimensionDialog
      dialogTitle={"Help"}
      dialogStartNode={<></>}
      dialogEndingNode={<></>}
      // No submit
      onDialogClose={() => handleClose(true)}
      primaryText={"OK"}
      open={open}
      onDialogSubmit={() => handleClose(true)}
      hideCancel={true}
      {...rest}>
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
      <OneOf index={i18n.language === "zh-CN" ? 0 : 1}>
        <Stack direction={"column"}>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            在Blender中安装节点树仓库插件，复制节点树代码，在插件中粘贴即可。节点树将会被加载到Blender项目中。您可以任意选择以下方式获取插件:
          </Typography>
          <DownloadLinks/>
          <a href={get_abs_url(node_tree_store_addon_intro_path)}
             target={"_blank"}>节点树仓库插件文档</a>
        </Stack>
        <Stack direction={"column"}>
          <Typography variant="body1" color="textSecondary" enableTranslate={false}>
            Install the NodeTreeStore Addon in Blender, copy the NodeTree code, and paste it in the
            Addon. The Node Tree will be loaded into your Blender project. You can get the Addon
            from:
          </Typography>
          <DownloadLinks/>
          <a href={get_abs_url(node_tree_store_addon_intro_path)}
             target={"_blank"}>Node Tree Store Addon Wiki</a>
        </Stack>
      </OneOf>

    </Stack>
  </DimensionDialog>
};

export default React.memo(NodeTreeHelperDialog);