import {abortUpload, commitUpload, getUploadUrl} from "./StorageService";
import {FileMetaData, UploadUrlResponse} from "../../proto/framework/storage/StorageMessage";
import axios from "axios";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import {CustomerProfile} from "../../proto/framework/customer/CustomerInfo";

const maxPartSize = 1024 * 1024 * 5;

export const getUploadParts = (file: File) => {
  // return 2;
  // Max 10000
  const part = Math.floor(file.size / maxPartSize);
  if (part <= 1) {
    return 1;
  }
  if (part > 10000) {
    return 10000;
  }
  return part;
}

export function cutFileIntoNBlobs(file: File, n: number) {
  const blobSize = Math.ceil(file.size / n);
  const blobs = [];

  // Each part must be at least 5MB in size
  for (let i = 0; i < n; i++) {
    const start = i * blobSize;
    const end = (i + 1) * blobSize;
    const blobSlice = file.slice(start, end);
    blobs.push(blobSlice);
    // const blob = new Blob([blobSlice]);
    // blobs.push(blob);
  }

  return blobs;
}

// Folder upload is not supported yet
// export async function uploadFolder() = {
//
// }

// uploadPostHandler is a function that will be called after the file is uploaded successfully
// if uploadPostHandler throws an error, the file will be deleted
export async function uploadFile(file: File, isPublic = false,
                                 onProgress: (progress: number) => void = () => void 0, batchController?: AbortController, uploadPostHandler?: (fileMetaData: FileMetaData) => Promise<void>,
                                 folderId?: string, keyInFolder?: string) {
  const controller = batchController ?? new AbortController();

  const partCount = getUploadParts(file);
  onProgress(2);
  const uploadUrl: UploadUrlResponse = await getUploadUrl(file, partCount);
  onProgress(10);
  const etags = Array<string>(uploadUrl.url.length).fill("");
  const allUploadPromises: Promise<void>[] = [];

  cutFileIntoNBlobs(file, uploadUrl.url.length).forEach((blob, index) => {
    const partUrl = uploadUrl.url[index];
    allUploadPromises.push(uploadPart(partUrl, blob, controller).then((response) => {
      etags[index] = response.headers.etag;
      onProgress(Math.min(Math.max(10, Math.ceil((index + 1) / uploadUrl.url.length * 100)), 95));
    }));
  });
  return Promise.all(allUploadPromises).then(async () => {
    const fileMetaData = await commitUpload(uploadUrl.id, etags, isPublic, 3, folderId, keyInFolder);
    onProgress(100);
    if (uploadPostHandler) {
      return uploadPostHandler(fileMetaData).then(() => {
        return fileMetaData;
      });
    }
    return fileMetaData;
  }).catch((err) => {
    // cancel allUploadPromises if any error
    controller.abort();
    abortUpload(uploadUrl.id);
    onProgress(0);
    throw err;
  });
}

export async function uploadFileFake(file: File, isPublic = false,
                                     onProgress: (progress: number) => void, error = false) {
  // const partCount = getUploadParts(file);
  const partCount = 100;
  onProgress(2);
  const uploadUrl: UploadUrlResponse = await new Promise((resolve) => {
    setTimeout(() => {
      const fakeUrl = UploadUrlResponse.create();
      fakeUrl.id = Math.random().toString();
      fakeUrl.url = new Array(partCount).fill("fakeUrl");
      resolve(fakeUrl);
    }, 1000);
  });
  onProgress(10);
  const allUploadPromises: Promise<void>[] = [];

  let completed = 0;
  cutFileIntoNBlobs(file, uploadUrl.url.length).forEach((blob, index) => {
    const partUrl = uploadUrl.url[index];
    allUploadPromises.push(uploadPartFake(partUrl, blob, 1, error).then((response) => {
      completed++;
      onProgress(Math.min(Math.max(10, Math.ceil(completed / uploadUrl.url.length * 100)), 100));
    }));
  });
  return Promise.all(allUploadPromises).then(() => {
    const fileMetaData = FileMetaData.create();
    const ownerProfile = CustomerProfile.create();
    ownerProfile.id = Math.random().toString();
    ownerProfile.name = "fake";
    ownerProfile.avatarUrl = "https://sm.ign.com/ign_ap/cover/a/avatar-gen/avatar-generations_hugw.jpg";
    fileMetaData.id = Math.random().toString();
    fileMetaData.name = file.name;
    fileMetaData.owner = ownerProfile;
    fileMetaData.contentType = file.type;
    fileMetaData.sizeInBytes = file.size;
    fileMetaData.createTime = Date.now();
    fileMetaData.isPublic = isPublic;
    fileMetaData.url = "https://static1.squarespace.com/static/56acc1138a65e2a286012c54/56ad05dfb09f9505c22897ef/5756ca76d51cd46816d2aa74/1613662125137/pixabaytest6-7.jpg?format=1500w";
    return fileMetaData;
  }).catch((err) => {
    onProgress(0);
    throw err;
  });

}

export const uploadPart = (url: string, file: Blob, controller: AbortController, retry = 3): Promise<any> => {
  return axios.put(url, file, {
    headers: {
      "Access-Control-Expose-Headers": "Etag",
    },
    signal: controller.signal,
  }).then((res) => {
    return res;
  }).catch((err) => {
    if (retry - 1 <= 0) {
      return Promise.reject(err);
    } else {
      return uploadPart(url, file, controller, retry - 1);
    }
  })
}

export const uploadPartFake = (url: string, file: Blob, retry = 3, error = false): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(new Error("Upload failed"));
      } else {
        resolve("fake");
      }
    }, Math.max(200, Math.ceil(Math.random() * 1000)));
  });
}

export const getReadableFileSize = (size?: number) => {
  if (size === undefined || size === null) {
    return "-";
  }
  if (size >= 1024 * 1024 * 1024) {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  } else if (size >= 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else if (size >= 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return size + " B";
  }
}


export const getFileIcon = (file: File) => {
  const fileType = file.type.split("/")[0];
  switch (fileType) {
    case "folder":
      return <FolderIcon/>;
    case "image":
      return <InsertPhotoIcon/>;
    case "text":
      return <DescriptionIcon/>;
    case "video":
      return <VideoFileIcon/>;
    case "audio":
      return <AudioFileIcon/>;
    case "font":
      return <FontDownloadIcon/>;
    default:
      return <InsertDriveFileIcon/>;
  }
}