import {t} from "i18next";

export const toReadableTime = (timeInMillisecond: number) => {
    const date = new Date(timeInMillisecond);
    return date.toLocaleString();
}

export const objectIdToTimestamp = (objectId: string) => {
    return parseInt(objectId.substring(0, 8), 16) * 1000;
}

export const timeForRender = (timeInMillisecond: number) => {
    return toDisplayTime(new Date(timeInMillisecond));
}

export const toDisplayTime = (dateTime: Date) => {
    if (!dateTime) {
        return "-";
    }
    const now: Date = new Date();
    const today: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const thisYear: Date = new Date(now.getFullYear(), 0, 1);
    const date: Date = new Date(dateTime);

    // Check if the date is today
    if (date >= today) {
        const diffMs: number = now.getTime() - date.getTime();
        const diffHours: number = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes: number = Math.floor(diffMs / (1000 * 60));

        if (diffHours < 1) {
            return `${diffMinutes}` + t(" minutes ago");
        } else {
            return `${diffHours}` + t(" hours ago");
        }
    }

    // Check if the date is this year
    if (date >= thisYear) {
        return `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    // For dates not in this year
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}