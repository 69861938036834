// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/application/nodetreestore/NodeTreeStoreMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {PageQuery, PageResult} from "../../framework/QueryMessage";
import {CollectorTargetProfile, UserCollectState} from "../../framework/collector/CollectorMessage";
import {CustomerProfile} from "../../framework/customer/CustomerInfo";
import {FileMetaData} from "../../framework/storage/StorageMessage";

export const protobufPackage = "proto.application.nodetreestore";

export enum SortType {
  /** DEFAULT - By relevance */
  DEFAULT = 0,
  UPDATE = 1,
  VIEW = 2,
  LIKE = 3,
  DOWNLOAD = 4,
  UNRECOGNIZED = -1,
}

export function sortTypeFromJSON(object: any): SortType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return SortType.DEFAULT;
    case 1:
    case "UPDATE":
      return SortType.UPDATE;
    case 2:
    case "VIEW":
      return SortType.VIEW;
    case 3:
    case "LIKE":
      return SortType.LIKE;
    case 4:
    case "DOWNLOAD":
      return SortType.DOWNLOAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SortType.UNRECOGNIZED;
  }
}

export function sortTypeToJSON(object: SortType): string {
  switch (object) {
    case SortType.DEFAULT:
      return "DEFAULT";
    case SortType.UPDATE:
      return "UPDATE";
    case SortType.VIEW:
      return "VIEW";
    case SortType.LIKE:
      return "LIKE";
    case SortType.DOWNLOAD:
      return "DOWNLOAD";
    case SortType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface NodeTreeQuery {
  query?: string | undefined;
  ownerId?: string | undefined;
  type?: string | undefined;
  engine?: string | undefined;
  sortType: SortType;
  pageQuery: PageQuery | undefined;
}

export interface SliceResponse {
  types: string[];
  engines: string[];
}

export interface NodeTreeQueryResponse {
  nodeTrees: NodeTreeProfile[];
  pageResult?: PageResult | undefined;
}

export interface NodeTreeBundle {
  jsonFile: FileMetaData | undefined;
  resources: FileMetaData[];
  avatar?: FileMetaData | undefined;
}

export interface NodeTreeUpdateRequest {
  id: string;
  description?: string | undefined;
  tags: string[];
  avatarId?: string | undefined;
}

export interface NodeTreeAddonCommitRequest {
  version: number;
  note: string;
  url: string;
}

export interface NodeTreeProfile {
  id: string;
  owner: CustomerProfile | undefined;
  name: string;
  description: string;
  type: string;
  engine: string;
  blenderVersion: string;
  tags: string[];
  avatarUrl?: string | undefined;
  isPublic: boolean;
  createAt: number;
  updateAt: number;
  /** 对象的被收藏统计信息 */
  collectProfile?:
    | CollectorTargetProfile
    | undefined;
  /** 当前用户对此的收藏状态 */
  userCollectState?: UserCollectState | undefined;
}

function createBaseNodeTreeQuery(): NodeTreeQuery {
  return {
    query: undefined,
    ownerId: undefined,
    type: undefined,
    engine: undefined,
    sortType: 0,
    pageQuery: undefined,
  };
}

export const NodeTreeQuery = {
  encode(message: NodeTreeQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.query !== undefined) {
      writer.uint32(10).string(message.query);
    }
    if (message.ownerId !== undefined) {
      writer.uint32(18).string(message.ownerId);
    }
    if (message.type !== undefined) {
      writer.uint32(26).string(message.type);
    }
    if (message.engine !== undefined) {
      writer.uint32(34).string(message.engine);
    }
    if (message.sortType !== 0) {
      writer.uint32(40).int32(message.sortType);
    }
    if (message.pageQuery !== undefined) {
      PageQuery.encode(message.pageQuery, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ownerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.engine = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sortType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pageQuery = PageQuery.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeQuery {
    return {
      query: isSet(object.query) ? globalThis.String(object.query) : undefined,
      ownerId: isSet(object.ownerId) ? globalThis.String(object.ownerId) : undefined,
      type: isSet(object.type) ? globalThis.String(object.type) : undefined,
      engine: isSet(object.engine) ? globalThis.String(object.engine) : undefined,
      sortType: isSet(object.sortType) ? sortTypeFromJSON(object.sortType) : 0,
      pageQuery: isSet(object.pageQuery) ? PageQuery.fromJSON(object.pageQuery) : undefined,
    };
  },

  toJSON(message: NodeTreeQuery): unknown {
    const obj: any = {};
    if (message.query !== undefined) {
      obj.query = message.query;
    }
    if (message.ownerId !== undefined) {
      obj.ownerId = message.ownerId;
    }
    if (message.type !== undefined) {
      obj.type = message.type;
    }
    if (message.engine !== undefined) {
      obj.engine = message.engine;
    }
    if (message.sortType !== 0) {
      obj.sortType = sortTypeToJSON(message.sortType);
    }
    if (message.pageQuery !== undefined) {
      obj.pageQuery = PageQuery.toJSON(message.pageQuery);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeQuery>, I>>(base?: I): NodeTreeQuery {
    return NodeTreeQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeQuery>, I>>(object: I): NodeTreeQuery {
    const message = createBaseNodeTreeQuery();
    message.query = object.query ?? undefined;
    message.ownerId = object.ownerId ?? undefined;
    message.type = object.type ?? undefined;
    message.engine = object.engine ?? undefined;
    message.sortType = object.sortType ?? 0;
    message.pageQuery = (object.pageQuery !== undefined && object.pageQuery !== null)
      ? PageQuery.fromPartial(object.pageQuery)
      : undefined;
    return message;
  },
};

function createBaseSliceResponse(): SliceResponse {
  return { types: [], engines: [] };
}

export const SliceResponse = {
  encode(message: SliceResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.types) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.engines) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SliceResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSliceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.types.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.engines.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SliceResponse {
    return {
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => globalThis.String(e)) : [],
      engines: globalThis.Array.isArray(object?.engines) ? object.engines.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SliceResponse): unknown {
    const obj: any = {};
    if (message.types?.length) {
      obj.types = message.types;
    }
    if (message.engines?.length) {
      obj.engines = message.engines;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SliceResponse>, I>>(base?: I): SliceResponse {
    return SliceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SliceResponse>, I>>(object: I): SliceResponse {
    const message = createBaseSliceResponse();
    message.types = object.types?.map((e) => e) || [];
    message.engines = object.engines?.map((e) => e) || [];
    return message;
  },
};

function createBaseNodeTreeQueryResponse(): NodeTreeQueryResponse {
  return { nodeTrees: [], pageResult: undefined };
}

export const NodeTreeQueryResponse = {
  encode(message: NodeTreeQueryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.nodeTrees) {
      NodeTreeProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.pageResult !== undefined) {
      PageResult.encode(message.pageResult, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeQueryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeTrees.push(NodeTreeProfile.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageResult = PageResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeQueryResponse {
    return {
      nodeTrees: globalThis.Array.isArray(object?.nodeTrees)
        ? object.nodeTrees.map((e: any) => NodeTreeProfile.fromJSON(e))
        : [],
      pageResult: isSet(object.pageResult) ? PageResult.fromJSON(object.pageResult) : undefined,
    };
  },

  toJSON(message: NodeTreeQueryResponse): unknown {
    const obj: any = {};
    if (message.nodeTrees?.length) {
      obj.nodeTrees = message.nodeTrees.map((e) => NodeTreeProfile.toJSON(e));
    }
    if (message.pageResult !== undefined) {
      obj.pageResult = PageResult.toJSON(message.pageResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeQueryResponse>, I>>(base?: I): NodeTreeQueryResponse {
    return NodeTreeQueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeQueryResponse>, I>>(object: I): NodeTreeQueryResponse {
    const message = createBaseNodeTreeQueryResponse();
    message.nodeTrees = object.nodeTrees?.map((e) => NodeTreeProfile.fromPartial(e)) || [];
    message.pageResult = (object.pageResult !== undefined && object.pageResult !== null)
      ? PageResult.fromPartial(object.pageResult)
      : undefined;
    return message;
  },
};

function createBaseNodeTreeBundle(): NodeTreeBundle {
  return { jsonFile: undefined, resources: [], avatar: undefined };
}

export const NodeTreeBundle = {
  encode(message: NodeTreeBundle, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jsonFile !== undefined) {
      FileMetaData.encode(message.jsonFile, writer.uint32(10).fork()).join();
    }
    for (const v of message.resources) {
      FileMetaData.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.avatar !== undefined) {
      FileMetaData.encode(message.avatar, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeBundle {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeBundle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jsonFile = FileMetaData.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resources.push(FileMetaData.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.avatar = FileMetaData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeBundle {
    return {
      jsonFile: isSet(object.jsonFile) ? FileMetaData.fromJSON(object.jsonFile) : undefined,
      resources: globalThis.Array.isArray(object?.resources)
        ? object.resources.map((e: any) => FileMetaData.fromJSON(e))
        : [],
      avatar: isSet(object.avatar) ? FileMetaData.fromJSON(object.avatar) : undefined,
    };
  },

  toJSON(message: NodeTreeBundle): unknown {
    const obj: any = {};
    if (message.jsonFile !== undefined) {
      obj.jsonFile = FileMetaData.toJSON(message.jsonFile);
    }
    if (message.resources?.length) {
      obj.resources = message.resources.map((e) => FileMetaData.toJSON(e));
    }
    if (message.avatar !== undefined) {
      obj.avatar = FileMetaData.toJSON(message.avatar);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeBundle>, I>>(base?: I): NodeTreeBundle {
    return NodeTreeBundle.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeBundle>, I>>(object: I): NodeTreeBundle {
    const message = createBaseNodeTreeBundle();
    message.jsonFile = (object.jsonFile !== undefined && object.jsonFile !== null)
      ? FileMetaData.fromPartial(object.jsonFile)
      : undefined;
    message.resources = object.resources?.map((e) => FileMetaData.fromPartial(e)) || [];
    message.avatar = (object.avatar !== undefined && object.avatar !== null)
      ? FileMetaData.fromPartial(object.avatar)
      : undefined;
    return message;
  },
};

function createBaseNodeTreeUpdateRequest(): NodeTreeUpdateRequest {
  return { id: "", description: undefined, tags: [], avatarId: undefined };
}

export const NodeTreeUpdateRequest = {
  encode(message: NodeTreeUpdateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.tags) {
      writer.uint32(26).string(v!);
    }
    if (message.avatarId !== undefined) {
      writer.uint32(34).string(message.avatarId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeUpdateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.avatarId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeUpdateRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      avatarId: isSet(object.avatarId) ? globalThis.String(object.avatarId) : undefined,
    };
  },

  toJSON(message: NodeTreeUpdateRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.avatarId !== undefined) {
      obj.avatarId = message.avatarId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeUpdateRequest>, I>>(base?: I): NodeTreeUpdateRequest {
    return NodeTreeUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeUpdateRequest>, I>>(object: I): NodeTreeUpdateRequest {
    const message = createBaseNodeTreeUpdateRequest();
    message.id = object.id ?? "";
    message.description = object.description ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.avatarId = object.avatarId ?? undefined;
    return message;
  },
};

function createBaseNodeTreeAddonCommitRequest(): NodeTreeAddonCommitRequest {
  return { version: 0, note: "", url: "" };
}

export const NodeTreeAddonCommitRequest = {
  encode(message: NodeTreeAddonCommitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.version !== 0) {
      writer.uint32(8).int32(message.version);
    }
    if (message.note !== "") {
      writer.uint32(18).string(message.note);
    }
    if (message.url !== "") {
      writer.uint32(26).string(message.url);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeAddonCommitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeAddonCommitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.note = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeAddonCommitRequest {
    return {
      version: isSet(object.version) ? globalThis.Number(object.version) : 0,
      note: isSet(object.note) ? globalThis.String(object.note) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: NodeTreeAddonCommitRequest): unknown {
    const obj: any = {};
    if (message.version !== 0) {
      obj.version = Math.round(message.version);
    }
    if (message.note !== "") {
      obj.note = message.note;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeAddonCommitRequest>, I>>(base?: I): NodeTreeAddonCommitRequest {
    return NodeTreeAddonCommitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeAddonCommitRequest>, I>>(object: I): NodeTreeAddonCommitRequest {
    const message = createBaseNodeTreeAddonCommitRequest();
    message.version = object.version ?? 0;
    message.note = object.note ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseNodeTreeProfile(): NodeTreeProfile {
  return {
    id: "",
    owner: undefined,
    name: "",
    description: "",
    type: "",
    engine: "",
    blenderVersion: "",
    tags: [],
    avatarUrl: undefined,
    isPublic: false,
    createAt: 0,
    updateAt: 0,
    collectProfile: undefined,
    userCollectState: undefined,
  };
}

export const NodeTreeProfile = {
  encode(message: NodeTreeProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.owner !== undefined) {
      CustomerProfile.encode(message.owner, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    if (message.engine !== "") {
      writer.uint32(50).string(message.engine);
    }
    if (message.blenderVersion !== "") {
      writer.uint32(58).string(message.blenderVersion);
    }
    for (const v of message.tags) {
      writer.uint32(66).string(v!);
    }
    if (message.avatarUrl !== undefined) {
      writer.uint32(74).string(message.avatarUrl);
    }
    if (message.isPublic !== false) {
      writer.uint32(80).bool(message.isPublic);
    }
    if (message.createAt !== 0) {
      writer.uint32(88).int64(message.createAt);
    }
    if (message.updateAt !== 0) {
      writer.uint32(96).int64(message.updateAt);
    }
    if (message.collectProfile !== undefined) {
      CollectorTargetProfile.encode(message.collectProfile, writer.uint32(106).fork()).join();
    }
    if (message.userCollectState !== undefined) {
      UserCollectState.encode(message.userCollectState, writer.uint32(114).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NodeTreeProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeTreeProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = CustomerProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.engine = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.blenderVersion = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.createAt = longToNumber(reader.int64());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.updateAt = longToNumber(reader.int64());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.collectProfile = CollectorTargetProfile.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.userCollectState = UserCollectState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeTreeProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      owner: isSet(object.owner) ? CustomerProfile.fromJSON(object.owner) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      engine: isSet(object.engine) ? globalThis.String(object.engine) : "",
      blenderVersion: isSet(object.blenderVersion) ? globalThis.String(object.blenderVersion) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : undefined,
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      createAt: isSet(object.createAt) ? globalThis.Number(object.createAt) : 0,
      updateAt: isSet(object.updateAt) ? globalThis.Number(object.updateAt) : 0,
      collectProfile: isSet(object.collectProfile) ? CollectorTargetProfile.fromJSON(object.collectProfile) : undefined,
      userCollectState: isSet(object.userCollectState) ? UserCollectState.fromJSON(object.userCollectState) : undefined,
    };
  },

  toJSON(message: NodeTreeProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.owner !== undefined) {
      obj.owner = CustomerProfile.toJSON(message.owner);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.engine !== "") {
      obj.engine = message.engine;
    }
    if (message.blenderVersion !== "") {
      obj.blenderVersion = message.blenderVersion;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.avatarUrl !== undefined) {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.createAt !== 0) {
      obj.createAt = Math.round(message.createAt);
    }
    if (message.updateAt !== 0) {
      obj.updateAt = Math.round(message.updateAt);
    }
    if (message.collectProfile !== undefined) {
      obj.collectProfile = CollectorTargetProfile.toJSON(message.collectProfile);
    }
    if (message.userCollectState !== undefined) {
      obj.userCollectState = UserCollectState.toJSON(message.userCollectState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeTreeProfile>, I>>(base?: I): NodeTreeProfile {
    return NodeTreeProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeTreeProfile>, I>>(object: I): NodeTreeProfile {
    const message = createBaseNodeTreeProfile();
    message.id = object.id ?? "";
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? CustomerProfile.fromPartial(object.owner)
      : undefined;
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? "";
    message.engine = object.engine ?? "";
    message.blenderVersion = object.blenderVersion ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.avatarUrl = object.avatarUrl ?? undefined;
    message.isPublic = object.isPublic ?? false;
    message.createAt = object.createAt ?? 0;
    message.updateAt = object.updateAt ?? 0;
    message.collectProfile = (object.collectProfile !== undefined && object.collectProfile !== null)
      ? CollectorTargetProfile.fromPartial(object.collectProfile)
      : undefined;
    message.userCollectState = (object.userCollectState !== undefined && object.userCollectState !== null)
      ? UserCollectState.fromPartial(object.userCollectState)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
