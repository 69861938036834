import * as React from 'react';
import {CommentProfile} from "../../../proto/framework/comment/CommentMessage";
import {Divider, IconButton, Link, Stack, StackProps} from "@mui/material";
import ProfileAvatar from "../../customer/component/ProfileAvatar";
import {Typography} from "../../../component/wrapper/MuiWrappers";
import {timeForRender} from "../../../api/util/TimeUtil";
import AttachedThread from "./AttachedThread";
import {ResourceType} from "../../../proto/framework/ResourceMessage";
import {
  commentOn,
  deleteComment,
  getLikeCount,
  getUserLike,
  likeComment,
  unlikeComment
} from "../CommentService";
import AlertContext from "../../../context/AlertContext";
import SendIcon from "@mui/icons-material/Send";
import EmojiTextField from "../../../component/input/EmojiTextField";
import {useIsUser} from "../../../hook/CommonUiHook";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {toReadableNumber} from "../../../api/util/StringUtil";
import {useTranslation} from "react-i18next";
import {get_customer_url} from "../../../navigation/routerUtil";
import PlainLink from "../../../component/wrapper/PlainLink";

type CommentItemProps = {
  targetType: ResourceType,
  targetId: string,
  comment: CommentProfile;
  showDivider?: boolean;
  attachTo?: string;
  onDeleted?: () => void;
  onReply?: (comment: CommentProfile) => void;
  canDelete?: boolean;
} & StackProps
const CommentItem = ({
                       targetType,
                       targetId,
                       comment,
                       showDivider = false,
                       attachTo,
                       onReply,
                       onDeleted,
                       canDelete,
                       sx, ...rest
                     }: CommentItemProps) => {

  const {showAlert} = React.useContext(AlertContext);
  const [replying, setReplying] = React.useState<boolean>(false);
  const [replyContent, setReplyContent] = React.useState<string>('');
  const [refresh, setRefresh] = React.useState<boolean | undefined>(void 0);
  const [like, setLike] = React.useState<boolean>(getUserLike(comment));
  const [likeCount, setLikeCount] = React.useState<number>(getLikeCount(comment));
  const [likeLoading, setLikeLoading] = React.useState<boolean>(false);
  const {t} = useTranslation();

  const isUser = useIsUser(comment.owner?.id);
  const canDeleteComment = canDelete || isUser;

  const sendReply = () => {
    if (!replyContent || replyContent.length === 0 || replyContent.trim().length === 0) {
      return Promise.resolve();
    }
    return commentOn(replyContent, targetType, targetId, comment.commentId, attachTo ? attachTo : comment.commentId)
    .then((newComment) => {
      if (onReply) {
        onReply(newComment);
      } else {
        comment.attachCount++;
        setRefresh(refresh === void 0 ? false : !refresh);
      }
    }).catch((e) => {
      showAlert(e.message, "error");
    });
  };

  return <Stack direction={"row"} spacing={2} sx={{...sx}} {...rest}>
    <ProfileAvatar title={comment.owner?.name}
                   profileLink={get_customer_url(comment.owner?.id)}
                   avatarUrl={comment.owner?.avatarUrl}/>
    <Stack direction={"column"} spacing={1} width={"100%"}>
      <Typography enableTranslate={false}
                  variant={"subtitle2"}
                  align={"left"}>{comment.owner?.name} {comment.replyTo ?
          <PlainLink
              style={{textDecoration: "none"}}
              to={get_customer_url(comment.replyTo?.owner?.id)} enableTranslate={false}>@
            {comment.replyTo.owner?.name} </PlainLink> : ""} </Typography>
      <Typography enableTranslate={false}
                  enableLinkify={true}
                  enableLineBreak={true}
                  align={"left"}>{comment.content}</Typography>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Typography enableTranslate={false}
                    variant={"caption"}
                    align={"left"}>{timeForRender(comment.createAt)}</Typography>
        <Link onClick={
          () => {
            if (likeLoading) {
              return;
            }
            setLikeLoading(true);
            if (!like) {
              likeComment(comment.commentId).then(() => {
                setLike(true);
                setLikeCount(likeCount + 1);
              }).catch((e) => {
                showAlert(e.message, "error");
              }).finally(() => {
                setLikeLoading(false);
              });
            } else {
              unlikeComment(comment.commentId).then(() => {
                setLike(false);
                setLikeCount(likeCount - 1);
              }).catch((e) => {
                showAlert(e.message, "error");
              }).finally(() => {
                setLikeLoading(false);
              });
            }
          }
        }><Typography enableTranslate={false}>{like ? <ThumbUpAltIcon/> :
            <ThumbUpOffAltIcon/>}{likeCount > 0 ? toReadableNumber(likeCount) : ""}
        </Typography></Link>
        <Link onClick={() => {
          setReplying(!replying);
        }}>{t("Reply")}</Link>
        {canDeleteComment && <Link
            color={"error"}
            onClick={() => {
              deleteComment(comment.commentId).then(() => {
                if (onDeleted) {
                  onDeleted();
                }
              }).catch((e) => {
                showAlert(e.message, "error");
              });
            }}>{t("Delete")}</Link>}
      </Stack>
      {replying && <EmojiTextField text={replyContent} setText={setReplyContent}
                                   focus={replying}
                                   placeholder={"Leave a comment"}>
        <IconButton onClick={() => {
          sendReply().then(() => {
            setReplyContent('');
            setReplying(false);
          }).catch((error) => {
            showAlert(error.message, "error");
          });
        }}>
          <SendIcon/>
        </IconButton>
      </EmojiTextField>}

      {comment.attachCount > 0 && <AttachedThread targetType={targetType}
                                                  targetId={targetId}
                                                  refresh={refresh}
                                                  canDelete={canDelete}
                                                  attachTo={comment.commentId}
                                                  count={comment.attachCount}/>}
      {showDivider && <Divider/>}
    </Stack>
  </Stack>;
};

export default CommentItem;