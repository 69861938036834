import * as React from 'react';
import {getTypeColor} from "../NodeTreeStoreUtil";

type NodeTreePreviewDefaultImgProps = {
  type: string;
} & React.SVGProps<SVGSVGElement>;
export const NodeTreePreviewDefaultImg = ({type, ...rest}: NodeTreePreviewDefaultImgProps) => {
  return (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={getTypeColor(type)} { ...rest}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <path fill="none" d="M0 0H24V24H0z"></path>
            <path
                d="M10 2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1H8v2h5V9c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-1H8v6h5v-1c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-1H7c-.552 0-1-.448-1-1V8H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h6zm9 16h-4v2h4v-2zm0-8h-4v2h4v-2zM9 4H5v2h4V4z"></path>
          </g>
        </g>
      </svg>
  );
};